/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 nn-seal.glb 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

export function NNSeal() {
  const { nodes, materials } = useGLTF('./logos/nn-seal.glb')
  const logo = useRef()

  useFrame(({ clock }) => {
    const ticks = clock.getElapsedTime()

    logo.current.rotation.z = ticks / -5
  })

  return (
    <>
      <directionalLight position={[0, 0, 10]} intensity={1} />
      <ambientLight intensity={1} />
      <group ref={logo} scale={12} rotation={[1.5708, 0, 0]} >
        <mesh geometry={nodes.Curve054.geometry} material={materials['SVGMat.040']} />
        <mesh geometry={nodes.Curve054_1.geometry} material={materials['SVGMat.002']} />
        <mesh geometry={nodes.Curve054_2.geometry} material={materials['SVGMat.003']} />
        <mesh geometry={nodes.Curve054_3.geometry} material={materials['SVGMat.004']} />
        <mesh geometry={nodes.Curve054_4.geometry} material={materials['SVGMat.005']} />
        <mesh geometry={nodes.Curve054_5.geometry} material={materials['SVGMat.006']} />
        <mesh geometry={nodes.Curve054_6.geometry} material={materials.SVGMat} />
        <mesh geometry={nodes.Curve054_7.geometry} material={materials['SVGMat.007']} />
        <mesh geometry={nodes.Curve054_8.geometry} material={materials['SVGMat.008']} />
        <mesh geometry={nodes.Curve054_9.geometry} material={materials['SVGMat.009']} />
        <mesh geometry={nodes.Curve054_10.geometry} material={materials['SVGMat.010']} />
        <mesh geometry={nodes.Curve054_11.geometry} material={materials['SVGMat.011']} />
        <mesh geometry={nodes.Curve054_12.geometry} material={materials['SVGMat.012']} />
        <mesh geometry={nodes.Curve054_13.geometry} material={materials['SVGMat.013']} />
        <mesh geometry={nodes.Curve054_14.geometry} material={materials['SVGMat.014']} />
        <mesh geometry={nodes.Curve054_15.geometry} material={materials['SVGMat.015']} />
        <mesh geometry={nodes.Curve054_16.geometry} material={materials['SVGMat.016']} />
        <mesh geometry={nodes.Curve054_17.geometry} material={materials['SVGMat.017']} />
        <mesh geometry={nodes.Curve054_18.geometry} material={materials['SVGMat.018']} />
        <mesh geometry={nodes.Curve054_19.geometry} material={materials['SVGMat.019']} />
        <mesh geometry={nodes.Curve054_20.geometry} material={materials['SVGMat.020']} />
        <mesh geometry={nodes.Curve054_21.geometry} material={materials['SVGMat.021']} />
        <mesh geometry={nodes.Curve054_22.geometry} material={materials['SVGMat.022']} />
        <mesh geometry={nodes.Curve054_23.geometry} material={materials['SVGMat.023']} />
        <mesh geometry={nodes.Curve054_24.geometry} material={materials['SVGMat.024']} />
        <mesh geometry={nodes.Curve054_25.geometry} material={materials['SVGMat.025']} />
        <mesh geometry={nodes.Curve054_26.geometry} material={materials['SVGMat.026']} />
        <mesh geometry={nodes.Curve054_27.geometry} material={materials['SVGMat.027']} />
        <mesh geometry={nodes.Curve054_28.geometry} material={materials['SVGMat.028']} />
        <mesh geometry={nodes.Curve054_29.geometry} material={materials['SVGMat.029']} />
        <mesh geometry={nodes.Curve054_30.geometry} material={materials['SVGMat.030']} />
        <mesh geometry={nodes.Curve054_31.geometry} material={materials['SVGMat.031']} />
        <mesh geometry={nodes.Curve054_32.geometry} material={materials['SVGMat.032']} />
        <mesh geometry={nodes.Curve054_33.geometry} material={materials['SVGMat.033']} />
        <mesh geometry={nodes.Curve054_34.geometry} material={materials['SVGMat.034']} />
        <mesh geometry={nodes.Curve054_35.geometry} material={materials['SVGMat.035']} />
        <mesh geometry={nodes.Curve054_36.geometry} material={materials['SVGMat.036']} />
        <mesh geometry={nodes.Curve054_37.geometry} material={materials['SVGMat.037']} />
        <mesh geometry={nodes.Curve054_38.geometry} material={materials['SVGMat.038']} />
        <mesh geometry={nodes.Curve054_39.geometry} material={materials['SVGMat.039']} />
        <mesh geometry={nodes.Curve054_40.geometry} material={materials['SVGMat.041']} />
        <mesh geometry={nodes.Curve054_41.geometry} material={materials['SVGMat.042']} />
        <mesh geometry={nodes.Curve054_42.geometry} material={materials['SVGMat.043']} />
        <mesh geometry={nodes.Curve054_43.geometry} material={materials['SVGMat.044']} />
        <mesh geometry={nodes.Curve054_44.geometry} material={materials['SVGMat.045']} />
      </group>
    </>
  )
}

// useGLTF.preload('./logos/nn-seal.glb')
