import React from "react";

export const Loading = () => {
    return (
        <>
            <div className="stars"></div>
            <div className="loading">
                <h1>JUMPING TO LIGHT SPEED!</h1>
            </div>
        </>
    )
}