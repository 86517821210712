/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

export default function GithubLogo() {
  const logo = useRef()
  const { nodes } = useGLTF('/logos/github-3d-logo-transformed.glb')

  useFrame(({ clock }) => {
    const ticks = clock.getElapsedTime()

    logo.current.rotation.z = ticks / 3
  })
  
  let loadedTexture = useTexture("/textures/purple_08_matCap.png")
  let loadedTextureTwo = useTexture("/textures/blue_09_matCap.png")

  return (
    <group ref={logo} rotation={[1.5708, 0, 0]} dispose={null} scale={35} >
      <mesh  geometry={nodes.Curve.geometry}>
        <meshMatcapMaterial matcap={loadedTexture} />
      </mesh>
      <mesh geometry={nodes.Curve001.geometry}>
        <meshMatcapMaterial matcap={loadedTextureTwo} />
      </mesh>
    </group>
  )
}

// useGLTF.preload('/logos/github-3d-logo-transformed.glb')
