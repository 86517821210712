/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

export function Planet01() {
  const fullPlanet = useRef()
  const planetRef = useRef()
  const cloudsRef = useRef()
  const clouds = useGLTF('/planets/purple-planet-clouds-transformed.glb')
  const planet = useGLTF('/planets/purple-planet-02-transformed.glb')

  //animates the position and rotation of the planet and clouds
  useFrame(({ clock }) => {
    planetRef.current.rotation.z = clock.getElapsedTime() / 8
    cloudsRef.current.rotation.z = clock.getElapsedTime() / 6 //rotate clouds slightly slower than planet

    // Planet in orbit
    fullPlanet.current.position.x = Math.cos(clock.getElapsedTime() / 10) * -150
    fullPlanet.current.position.y = Math.cos(clock.getElapsedTime() / 12) * 30
    fullPlanet.current.position.z = Math.sin(clock.getElapsedTime() / 10) * -50
  })

  return (
    <group ref={fullPlanet} rotation={[-Math.PI / 2, 0.50, 0]} scale={30}>
      <group ref={cloudsRef}>
        <mesh geometry={clouds.nodes.Clouds_0_0.geometry} material={clouds.materials.Clouds_0} />
      </group>
      <group ref={planetRef}>
        <mesh geometry={planet.nodes.Planet_0.geometry} material={planet.materials.PurplePlanet} />
      </group>
    </group>
  )
}

useGLTF.preload('/planets/purple-planet-clouds-transformed.glb')
useGLTF.preload('/planets/purple-planet-02-transformed.glb')